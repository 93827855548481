// node modules
import React from 'react'
import styled from 'styled-components'

// shared
import LazyImage from 'components/shared/lazy-image'
import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'

// styles
import media from 'styles/media'

// types
import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div`
  position: relative;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.lg.min} {
    height: 100vh;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const ContentWrapper = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 15%;

  h2 {
    color: ${({ theme }) => theme.colors.white};
  }
`

type Props = {
  image: {
    src: ImageDataLike
    alt: string
  }
  heading: string
}

const Infobox: React.FC<Props> = ({ image, heading }) => {
  return (
    <Wrapper>
      <Background>
        <LazyImage src={image.src} alt={image.alt} fill />
      </Background>
      <Container>
        <ContentWrapper>
          <Heading
            as="h2"
            size={57}
            dangerouslySetInnerHTML={{ __html: heading }}
            transform="uppercase"
            weight={900}
            line="100%"
            margin="0"
          />
        </ContentWrapper>
      </Container>
    </Wrapper>
  )
}

export default Infobox

// node modules
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// shared
import ButtonWithIcon from 'components/shared/button-with-icon'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'

// styles
import media from 'styles/media'

// types
import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.section`
  position: relative;
  padding-top: 250px;
  padding-bottom: 100px;

  ${media.md.max} {
    padding-top: 60px;
  }
`

const ImagesWrapper = styled.div`
  height: 65vh;

  .first-image {
    width: 40%;
  }
  .second-image {
    width: 60%;
  }

  ${media.md.max} {
    height: auto;

    .first-image {
      width: 100%;
      height: 50vh;
    }
    .second-image {
      width: 100%;
      height: 50vh;
    }
  }
`

const StyledHeading = styled(Heading)`
  position: absolute;
  z-index: 1;
  left: 70%;
  transform: translate(-50%, -50%);

  ${media.md.max} {
    position: static;
    transform: translate(0%, 0%);
    padding: 0 1.35rem;
    margin-bottom: 35px;
  }
`

const ContentWrapper = styled.div`
  margin-left: 40%;
  padding-top: 40px;
  width: 40%;

  ${media.md.max} {
    margin-left: 0;
    width: 100%;
    padding: 30px 1.35rem;
  }
`

type Props = {
  heading: string
  firstImage: {
    src: ImageDataLike
    alt: string
  }
  secondImage: {
    src: ImageDataLike
    alt: string
  }
  subheading: string
  paragraph: string
  buttonText: string
  buttonLink: string
  contentId?: string
}

const SideBySideWithContent: React.FC<Props> = ({
  heading,
  firstImage,
  secondImage,
  subheading,
  paragraph,
  buttonText,
  buttonLink,
  contentId,
}) => {
  return (
    <Wrapper id="serwis">
      <ImagesWrapper>
        <StyledHeading
          as="h2"
          dangerouslySetInnerHTML={{ __html: heading }}
          transform="uppercase"
          weight={900}
          line="100%"
        />
        <LazyImage
          src={firstImage.src}
          alt={firstImage.alt}
          className="first-image"
          fill
        />
        <LazyImage
          src={secondImage.src}
          alt={secondImage.alt}
          className="second-image"
          fill
        />
      </ImagesWrapper>
      <ContentWrapper id={contentId ?? ''}>
        <Text
          dangerouslySetInnerHTML={{ __html: subheading }}
          transform="uppercase"
          as="h2"
          weight={700}
          size={26}
          line="25px"
          margin="30px"
        />
        <Text
          dangerouslySetInnerHTML={{ __html: paragraph }}
          size={17}
          line="26px"
          margin="30px"
        />
        <ButtonWithIcon as={Link} to={buttonLink}>
          {buttonText}
        </ButtonWithIcon>
      </ContentWrapper>
    </Wrapper>
  )
}

export default SideBySideWithContent

import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

import Breadcrumbs from 'components/shared/breadcrumbs'

import Hero from 'components/pages/sport/hero'
import About from 'components/pages/sport/about'
import Infobox from 'components/pages/sport/infobox'
import SideBySideWithContent from 'components/shared/side-by-side-with-content'

const SportPage: React.FC<PageProps<Queries.SportPageQuery>> = ({ data }) => {
  const PAGE = data.wpPage?.sport
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const HERO_SLIDES =
    PAGE?.sportBannerHero
      ?.filter((slide) => slide?.sportBannerHeroImg)
      ?.map((slide) => ({
        src: slide?.sportBannerHeroImg?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: slide?.sportBannerHeroImg?.altText!,
      })) || []

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <Hero heading={PAGE?.sportBannerTitle!} slides={HERO_SLIDES} />

      <Breadcrumbs crumbs={[{ name: data.wpPage?.title! }]} />

      <About
        firstHeading={PAGE?.sportAboutSportTitle!}
        secondHeading={PAGE?.sportExperienceTitle!}
        firstVideoUrl={PAGE?.sportAboutSportFilm!}
        secondVideoUrl={PAGE?.sportExperienceFilm!}
        firstDescription={PAGE?.sportAboutSportDescription!}
        secondDescription={PAGE?.sportExperienceDescription!}
        buttonText={PAGE?.sportExperienceButtonText!}
        buttonLink={PAGE?.sportExperienceButtonLink!}
      />

      <Infobox
        heading={PAGE?.sportSeparatorTitle!}
        image={{
          src: PAGE?.sportSeparatorImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.sportSeparatorImg?.altText!,
        }}
      />

      <SideBySideWithContent
        heading={PAGE?.sportTrainingTitle!}
        firstImage={{
          src: PAGE?.sportTrainingImgLeft?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.sportTrainingImgLeft?.altText!,
        }}
        secondImage={{
          src: PAGE?.sportTrainingImgRight?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.sportTrainingImgRight?.altText!,
        }}
        subheading={PAGE?.sportTrainingSubtitle!}
        paragraph={PAGE?.sportTrainingDescription!}
        buttonText={PAGE?.sportTrainingButtonText!}
        buttonLink={PAGE?.sportTrainingButtonLink!}
      />
    </Layout>
  )
}

export default SportPage

export const query = graphql`
  query SportPage {
    wpPage(slug: { regex: "/sport/" }) {
      seo {
        ...WpSEO
      }
      title
      sport {
        sportAboutSportDescription
        sportAboutSportFilm
        sportAboutSportTitle
        sportBannerTitle
        sportExperienceDescription
        sportExperienceButtonText
        sportExperienceButtonLink
        sportExperienceFilm
        sportExperienceTitle
        sportSeparatorTitle
        sportTrainingButtonLink
        sportTrainingButtonText
        sportTrainingDescription
        sportTrainingSubtitle
        sportTrainingTitle
        sportBannerHero {
          sportBannerHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: DOMINANT_COLOR
                  breakpoints: [1280, 1920]
                )
              }
            }
          }
        }
        sportSeparatorImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        sportTrainingImgLeft {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 650)
            }
          }
        }
        sportTrainingImgRight {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
    }
  }
`

import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import Player from 'components/shared/player'
import ButtonWithIcon from 'components/shared/button-with-icon'

import media from 'styles/media'

import useBreakpoint from 'hooks/useBreakpoint'

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0;
  }
`

const Wrapper = styled.div`
  padding: 50px 0;
`

const FirstSectionWrapper = styled.div``

const FirstHeading = styled(Heading)`
  padding: 0 22px;

  ${media.lg.min} {
    padding: 0;
    position: relative;
    left: 50%;
  }
`
const SecondHeading = styled(Heading)`
  padding: 0 22px;
  ${media.lg.min} {
    padding: 0;
    position: relative;
    left: 10%;
  }
`

const PlayerWrapper = styled.div`
  height: 60vh;

  ${media.lg.min} {
    height: auto;
    width: 65%;
    margin-top: 20px;
  }
`

const DescriptionWrapper = styled.div`
  padding: 50px 22px;

  p {
    line-height: 22px;
  }

  ${media.lg.min} {
    padding: 70px 0;

    width: 35%;

    p {
      line-height: 26px;
    }
  }
`

const SecondSectionWrapper = styled.div`
  ${media.lg.min} {
    ${PlayerWrapper} {
      margin-left: auto;
    }

    ${DescriptionWrapper} {
      margin-left: 35%;
    }
  }
`

type Props = {
  firstHeading: string
  secondHeading: string
  firstVideoUrl: string
  secondVideoUrl: string
  firstDescription: string
  secondDescription: string
  buttonText?: string
  buttonLink?: string
}

const About: React.FC<Props> = ({
  firstHeading,
  secondHeading,
  firstVideoUrl,
  secondVideoUrl,
  firstDescription,
  secondDescription,
  buttonText,
  buttonLink,
}) => {
  const { lg } = useBreakpoint()

  return (
    <StyledContainer>
      <Wrapper>
        <FirstSectionWrapper>
          <FirstHeading
            size={67}
            transform="uppercase"
            line="100%"
            margin="40px"
            dangerouslySetInnerHTML={{ __html: firstHeading }}
          />

          <PlayerWrapper>
            <Player videoSrcUrl={firstVideoUrl} />
          </PlayerWrapper>
          <DescriptionWrapper>
            <Text dangerouslySetInnerHTML={{ __html: firstDescription }} />
          </DescriptionWrapper>
        </FirstSectionWrapper>
        <SecondSectionWrapper>
          <SecondHeading
            size={lg ? 80 : 60}
            transform="uppercase"
            line="100%"
            margin="40px"
            dangerouslySetInnerHTML={{ __html: secondHeading }}
          />

          <PlayerWrapper>
            <Player videoSrcUrl={secondVideoUrl} />
          </PlayerWrapper>
          <DescriptionWrapper>
            <Text
              dangerouslySetInnerHTML={{ __html: secondDescription }}
              margin="40px"
            />
            {buttonLink && buttonText && (
              <ButtonWithIcon as="a" href={buttonLink}>
                {buttonText}
              </ButtonWithIcon>
            )}
          </DescriptionWrapper>
        </SecondSectionWrapper>
      </Wrapper>
    </StyledContainer>
  )
}

export default About

// node modules
import React from 'react'
import styled from 'styled-components'
import { Autoplay, Pagination, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// shared
import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'
import { Heading } from 'components/shared/typography'

// styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import media from 'styles/media'

// types
import { IGatsbyImageData } from 'gatsby-plugin-image'

const Wrapper = styled.section`
  position: relative;
`

const SwiperWrapper = styled.div`
  height: 100vh;
  position: relative;

  .swiper-pagination {
    bottom: 20px !important;

    ${media.md.max} {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background-color: #f6f2f2;
    width: 10px;
    height: 10px;
    margin: 0 12px !important;
  }

  ${media.lg.max} {
    .swiper-slide.slide-0 {
      img {
        object-position: 42% 0;
      }
    }
  }

  ${media.lg.max} {
    .swiper-slide.slide-1 {
      img {
        object-position: 75% 0;
      }
    }
  }

  ${media.lg.max} {
    .swiper-slide.slide-2 {
      img {
        object-position: 46% 0;
      }
    }
  }
`

const SlideWrapper = styled.div`
  height: 100vh;
`

const ContentContainer = styled(Container)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1;
`

type HeroProps = {
  heading: string
  slides: Array<{
    src: IGatsbyImageData
    alt: string
  }>
}

const Hero: React.FC<HeroProps> = ({ heading, slides }) => {
  return (
    <Wrapper id="hero">
      <SwiperWrapper>
        <Swiper
          modules={[Autoplay, Pagination, EffectFade]}
          spaceBetween={0}
          slidesPerView={1}
          initialSlide={0}
          speed={1500}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          effect="fade"
          pagination={{ clickable: true }}
        >
          {slides.map(({ src, alt }, index) => (
            <SwiperSlide key={`${index}-${alt}`} className={`slide-${index}`}>
              <SlideWrapper>
                <LazyImage src={src} alt={alt} fill loading="eager" />
              </SlideWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>
      <ContentContainer>
        <Heading
          as="h2"
          dangerouslySetInnerHTML={{ __html: heading }}
          transform="uppercase"
          themecolor="white"
          weight={900}
          line="100%"
        />
      </ContentContainer>
    </Wrapper>
  )
}

export default Hero
